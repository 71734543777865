// import modules, libraries, pages, hooks and components
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

// import images and icons
import notfound from "../asserts/img/notfound.png";

const PageNotFound = () => {
    // media queries 
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div className="page-not-found">
            <img src={notfound} alt="404" style={isMobile ? (
                {
                    width: '300px',
                    height: 'auto',
                }
            ) : (
                {
                    width: '400px',
                    height: 'auto'
                }
            )} />
            <p style={{
                fontSize: '25px',
                fontVariant: 'small-caps',
                marginTop: "-20px"
            }}>The page you are trying to access does not exist!</p>
            <Link to="/" style={{color: "dodgerblue"}} >Go Home</Link>
        </div>
    );
}
 
export default PageNotFound;
