// import modules, libraries, pages, hooks and components
import { Link as RouterLink} from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
import { useMediaQuery } from 'react-responsive';

// import images, files and icons
import thinking from '../asserts/img/thinking.png';
import cv from '../asserts/files/CV.pdf';

const Services = () => {
    // media queries 
    const isDesktop = useMediaQuery({ minWidth: 1200 });

    return (
        <section className="services" id="services">
            <div className="text-box">
                <h3>Services</h3>
                <h2>Deploy My Expertise</h2>
                <section className='services-bio'>
                    <p>
                        Imagine having a tech wizard on your team—I'm not just a full-stack web developer; 
                        I'm a tech enthusiast on a perpetual quest for digital magic. 
                        I don't just solve problems; 
                        I orchestrate solutions with a logical flair that makes coding an art form. 
                        Picture me not as just an innovator, but as an ethical hacker, 
                        safeguarding your digital realm.
                    </p>
                    <p>
                        But wait, there's more! I'm not confined to the binary world alone. 
                        I express my tales through blogs and sprinkle a dash of creativity as an animator. 
                        So, if you're looking for more than a developer—if you're seeking a tech sorcerer who crafts solutions, 
                        tells stories, and adds a sprinkle of magic—then let's conjure something extraordinary together!
                    </p>
                </section>
                <div className="text-box-buttons">
                    <ScrollLink 
                        to="contact" 
                        className="btn" 
                        smooth={true}
                        spy={true}
                        duration={500}
                        offset={-50}
                        style={{color: "dodgerblue",}}
                    >Hire Me</ScrollLink>
                    <RouterLink 
                        to={cv} 
                        className="btn" 
                        download="Fidelis Musamba - CV"
                        style={{color: "dodgerblue",}}
                        rel="noreferrer"
                        target="_blank"
                    >Download CV</RouterLink>
                </div>
            </div>
            {isDesktop && (
                <div className="text-box-sider">
                    <img src={thinking} alt="</>" />
                </div>
            )}
        </section>
    );
}
 
export default Services;
