// import modules, libraries, pages, hooks and components
import Navbar from "../components/Navbar";
import About from "../components/About";
import Abilities from "../components/Abilities";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";

const LandingPage = () => {
    return (
        <div className="landing-page">
            <Navbar />
            <main>
                <About />  
                <Abilities />
                <Services />
                <Portfolio />
                <Contact />
            </main>
        </div>
    );
}
 
export default LandingPage;
