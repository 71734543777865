// import modules, libraries, pages, hooks and components
import { useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import Socials from "./Socials";
import { useMediaQuery } from 'react-responsive';

// import images and icons
import fidelis from '../asserts/img/fidelis.png';
import blocks from "../asserts/img/blocks.png";

const About = () => {
    // media queries 
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isTablet = useMediaQuery({ minWidth: 768 });
    
    useEffect(() => {
        // animation classes and triggers
        const imageBox = document.querySelector('.image-box');
        const blocks = document.querySelector('.blocks');
        const boxDescription = document.querySelector('.box-description');

        if (imageBox && blocks && boxDescription) {
            imageBox.classList.add('animate-image-box');
            blocks.classList.add('animate-blocks');
            boxDescription.classList.add('animate-box-description');
        }
    }, []);
    
    return (
        <section className="about">
            <div className="image-box">
                <img src={fidelis} alt="loading..." />
            </div>
            <div className="blocks">
                <img src={blocks} alt="*" />
            </div>
            <div className="box-description">
                <h2>Hi, I am <span
                    style={{color: "dodgerblue",}}>Fidelis Musamba</span>
                </h2>
                <TypeAnimation
                    sequence={[
                        'a full stack web developer.',
                        2000,
                        'a tech enthusiast & lifelong learner.',
                        2000,
                        'a problem solver & logical thinker.',
                        2000, 
                        'an innovator & ethical hacker.',
                        2000,
                        'a blogger & animator.',
                        2000,
                        'a son, brother, friend & Christian.',
                        2000
                    ]}

                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={isDesktop ? (
                        { 
                            fontSize: '1em', 
                            width: '500px',
                            display: 'block'
                        }
                    ) : isTablet ? (
                        { 
                            fontSize: '1em', 
                            width: '100%',
                            display: 'block'
                        }
                    ) : (
                        { 
                            fontSize: '18px', 
                            width: '100%',
                            display: 'block'
                        }
                    )}
                />
                <p>
                    "The mind is a kaleidoscope of dreams; 
                    dare to turn the wheel and witness the magic of your imagination. 
                    Dance with uncertainty, for in its rhythm, 
                    you'll discover the music of endless possibilities."
                </p>
                {isTablet ? (
                    <section className="tablet-center">
                        <Socials />
                    </section>
                ) : (
                    <section>
                        <Socials />
                    </section>
                )}
            </div>
        </section>
    );
}
 
export default About;
