// import modules, libraries, pages, hooks and components

// import images and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { 
    faLaptopCode,
    faPalette,
    faDatabase,
    faSearch,
    faDiagramProject,
    faNetworkWired,
    faReplyAll,
    faPeopleGroup
} from "@fortawesome/free-solid-svg-icons";

const Abilities = () => {
    return (
        <section className="abilities" id="abilities">
            <h3>Abilities</h3>
            <h2>What I Can Do</h2>
            <div className="skill-section">
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faLaptopCode} size="2xl" className="fa-icon" />
                    <h2>Backend Web Development</h2>
                    <p>
                        I primarily use JavaScript, especially Node.js and Express.js, for backend development. 
                        I'm also proficient in Core PHP, R (Shiny R), 
                        and can swiftly adapt to Python and Java frameworks like Django, Flask, and Springboot. 
                        Additionally, I employ WordPress for content management when required.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faPalette} size="2xl" className="fa-icon" />
                    <h2>Frontend Web Design</h2>
                    <p>
                        I predominantly use React.js and vanilla CSS for frontend development. 
                        Nevertheless, I'm proficient in working with HTML, vanilla CSS, and vanilla JavaScript, as well as EJS. 
                        My flexibility extends to various frontend stacks, allowing me to seamlessly adapt to different project requirements.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faDatabase} size="2xl" className="fa-icon" />
                    <h2>Database Development</h2>
                    <p>
                        I proficiently handle both relational and non-relational databases, 
                        leveraging MySQL for SQL and MongoDB for NoSQL. 
                        This enables me to develop versatile and efficient project databases, 
                        ensuring optimal data management and retrieval tailored to specific project needs.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faGithub} size="2xl" className="fa-icon" />
                    <h2>Version Control</h2>
                    <p>
                        I am skilled in Version Control using Git, GitHub, and GitLab, 
                        ensuring precise version tracking and seamless integration for streamlined workflows. 
                        This proficiency contributes to robust, well-documented codebases, 
                        enhancing project collaboration.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faReplyAll} size="2xl" className="fa-icon" />
                    <h2>Application Programming Interface</h2>
                    <p>
                        I specialize in RESTful APIs, ensuring seamless communication 
                        and efficient data exchange between applications. 
                        Proficient in design and implementation, 
                        I contribute to scalable systems by adhering to REST principles.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faDiagramProject} size="2xl" className="fa-icon" />
                    <h2>Agile Project Development</h2>
                    <p>
                        I specialize in Agile Project Development, 
                        emphasizing collaboration and adaptability for efficient software delivery. 
                        With a focus on iterative cycles, I prioritize client-centric outcomes. 
                        Embracing the Agile mindset, I foster continuous improvement for dynamic project evolution.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faSearch} size="2xl" className="fa-icon" />
                    <h2>Search Engine Optimization</h2>
                    <p>
                        I specialize in SEO strategies, driving organic traffic through precise keyword research, 
                        content optimization, and strategic link-building. 
                        Staying updated on the latest trends, I use data-driven methods to refine website performance, 
                        aiming for sustained, long-term online growth.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faNetworkWired} size="2xl" className="fa-icon" />
                    <h2>Blogging & Ethical Hacking</h2>
                    <p>
                        Blogging is my outlet for sharing insights, fostering collaborative discussions. 
                        In Ethical Hacking, I use my skills responsibly to identify and rectify 
                        software vulnerabilities, contributing to secure digital landscapes. 
                        Both reflect my commitment to communication and software integrity.
                    </p>
                </div>
                <div className="skill-section-box">
                    <FontAwesomeIcon icon={faPeopleGroup} size="2xl" className="fa-icon" />
                    <h2>Team Player & Open Sourcerer</h2>
                    <p>
                        As a team player, I thrive in collaborative environments with open communication. 
                        Actively contributing to both collective goals and open source projects, 
                        I foster a positive and supportive atmosphere. Embracing adaptability and feedback, 
                        I believe in the synergy of cohesive teamwork for project success.
                    </p>
                </div>
            </div>
        </section>
    );
}
 
export default Abilities;
