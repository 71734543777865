// import modules, libraries, pages, hooks and components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Blog from './pages/Blog';
import PageNotFound from './pages/PageNotFound';
import Footer from './components/Footer';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
      <div className="app-body">
          <Routes>
            <Route
              path="/"
              element={<LandingPage />}
            />
            <Route
              path="/blog"
              element={<Blog />}
            />
            <Route 
              path="*"
              element={<PageNotFound />}
            />
          </Routes>
      </div>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
