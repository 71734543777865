// import modules, libraries, pages, hooks and components
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const ScrollNavbar = ({ logo }) => {
    // state management
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
    const handleScroll = () => {
        const scrolled = window.scrollY > 110;
        setIsScrolled(scrolled);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);

    return (
    <div className={`navbar ${isScrolled ? 'scrolled' : 'hidden'}`}>
        <nav className="main">
            <div className="logo">
                <img src={logo} alt="DevFidelis" style={{width: "70px",}}/>
                <h1 style={{fontSize: "18px",}}>{`<DevFidelis />`}</h1>
            </div>
            <div className="links" style={{marginTop: "10px",}}>
                <ScrollLink 
                    to="home"  
                    className="nav-link"
                    offset={-20}
                >Home</ScrollLink>
                <ScrollLink 
                    to="abilities" 
                    className="nav-link"
                    activeClass="active-link"
                    smooth={true}
                    spy={true} 
                    duration={500}
                    offset={-50}
                >Abilities</ScrollLink>
                <ScrollLink 
                    to="services"
                    className="nav-link"
                    activeClass="active-link" 
                    smooth={true} 
                    spy={true}
                    duration={500}
                    offset={-50} 
                >Services</ScrollLink>
                <ScrollLink 
                    to="portfolio" 
                    className="nav-link"
                    activeClass="active-link"
                    smooth={true}  
                    spy={true}
                    duration={500}
                    offset={-50}
                >Portfolio</ScrollLink>
                <RouterLink to="/blog" className="nav-link">Blog</RouterLink>
            </div>
            <div className="edge" style={{marginTop: "10px",}}>
                <ScrollLink 
                    to="contact" 
                    className="nav-link"
                    smooth={true} 
                    duration={500}
                    offset={-50}
                    style={{ color: "dodgerblue" }}      
                >Contact Me</ScrollLink>
            </div>
        </nav>
    </div>
    );
};

export default ScrollNavbar;
