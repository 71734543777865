// import modules, libraries, pages, hooks and components
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import ScrollNavbar from './ScrollNavbar';
import ScrollNavbarMobile from './ScrollNavbarMobile';

// import images and icons
import logo from "../asserts/img/logo.jpg";

const Navbar = () => {
    // media queries 
    const isMobile = useMediaQuery({ maxWidth: 1199 });

    return (
        <header id="home">
            {isMobile ? (
                // Render mobile-friendly header
                <ScrollNavbarMobile logo={logo} />
            ) : (
                // Render desktop header
                <ScrollNavbar logo={logo} />
            )}
            <nav className="main mobile-hide">
                <div className="logo">
                    <img src={logo} alt="DevFidelis" />
                    <h1>{`<DevFidelis />`}</h1>
                </div>
                <div className="links">
                    <ScrollLink 
                        to="abilities" 
                        className="nav-link"
                        activeClass="active-link"
                        smooth={true}
                        spy={true} 
                        duration={500}
                        offset={-50}
                    >Abilities</ScrollLink>
                    <ScrollLink 
                        to="services"
                        className="nav-link"
                        activeClass="active-link" 
                        smooth={true} 
                        spy={true}
                        duration={500}
                        offset={-50} 
                    >Services</ScrollLink>
                    <ScrollLink 
                        to="portfolio" 
                        className="nav-link"
                        activeClass="active-link"
                        smooth={true}  
                        spy={true}
                        duration={500}
                        offset={-50}
                    >Portfolio</ScrollLink>
                    <RouterLink to="/blog" className="nav-link">Blog</RouterLink>
                </div>
                <div className="edge">
                    <ScrollLink 
                        to="contact"
                        className="nav-link"
                        smooth={true} 
                        duration={500}
                        offset={-50}  
                        style={{ color: "dodgerblue" }}
                    >Contact Me</ScrollLink>
                </div>
            </nav>
        </header>
    );
}
 
export default Navbar;
