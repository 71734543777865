// import modules, libraries, pages, hooks and components
import { Link } from 'react-router-dom';

// import images and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSquareFacebook, 
    faSquareInstagram, 
    faSquareXTwitter,
    faLinkedin, 
    faSquareGithub
} from '@fortawesome/free-brands-svg-icons';

const Socials = ({ size }) => {
    return (
        <div className="socials">
            <Link to="https://www.linkedin.com/in/fidelis-musamba-892ba4178/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} size={size} style={{color: "#ffffff",}} />
            </Link>
            <Link to="https://github.com/DevFidelis" target="_blank">
                <FontAwesomeIcon icon={faSquareGithub} size={size} style={{color: "#ffffff",}} />
            </Link>
            <Link to="https://twitter.com/DevFidelis" target="_blank">
                <FontAwesomeIcon icon={faSquareXTwitter} size={size} style={{color: "#ffffff",}} />
            </Link>
            <Link to="https://www.facebook.com/DevFidelisMusamba/" target="_blank">
                <FontAwesomeIcon icon={faSquareFacebook} size={size} style={{color: "#ffffff",}} />
            </Link>
            <Link to="https://www.instagram.com/DevFidelis/" target="_blank">
                <FontAwesomeIcon icon={faSquareInstagram} size={size} style={{color: "#ffffff",}} />
            </Link>
        </div>
    );
}
 
export default Socials;
