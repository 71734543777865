// import modules, libraries, pages, hooks and components
import React, { useState, useEffect } from 'react';

// import images and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';

const BackToTopArrow = () => {
  //  state management
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 100;
      setIsVisible(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`back-to-top ${isVisible ? 'visible' : 'hidden'}`} onClick={scrollToTop}>
      <FontAwesomeIcon icon={faCircleArrowUp} size='2xl' style={{color: "#005a9c",}} />
    </div>
  );
};

export default BackToTopArrow;
