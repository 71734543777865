// import modules, libraries, pages, hooks and components
import { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

// import images and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';


const ScrollNavbarMobile = ({ logo }) => {
    // State management for mobile menu visibility
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Toggle mobile menu
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className={`navbar-mobile ${isMobileMenuOpen ? 'open' : 'closed'}`}>
        <div className="logo">
            <img src={logo} alt="DevFidelis" style={{ width: '50px', marginTop: '-2px', paddingTop: "6px" }} />
        </div>
        <ScrollLink 
            to="home"
            className="navbar-mobile-heading"
            offset={-20}
        ><h1>{'<DevFidelis />'}</h1></ScrollLink>
        <div className="menu-icon" onClick={toggleMobileMenu}>
            {!isMobileMenuOpen && <FontAwesomeIcon icon={faBars} size="2xl" style={{color: "#ffffff", opacity: "0.8",}} />}
            {isMobileMenuOpen && <FontAwesomeIcon icon={faX} size="2xl" style={{color: "#ffffff", opacity: "0.7", }} />}
        </div>
        {isMobileMenuOpen && (
            <div className="mobile-menu">
                <ScrollLink 
                    to="abilities" 
                    className="nav-link"
                    activeClass="active-link"
                    smooth={true}
                    spy={true} 
                    duration={500}
                    offset={-50}
                >Abilities</ScrollLink>
                <ScrollLink 
                    to="services"
                    className="nav-link"
                    activeClass="active-link" 
                    smooth={true} 
                    spy={true}
                    duration={500}
                    offset={-50} 
                >Services</ScrollLink>
                <ScrollLink 
                    to="portfolio" 
                    className="nav-link"
                    activeClass="active-link"
                    smooth={true}  
                    spy={true}
                    duration={500}
                    offset={-50}
                >Portfolio</ScrollLink>
                <RouterLink to="/blog" className="nav-link">Blog</RouterLink>
                <ScrollLink 
                    to="contact"
                    className="nav-link"
                    smooth={true} 
                    spy={true}
                    duration={500}
                    offset={-50}
                >Contact Me</ScrollLink>
            </div>
        )}
        </div>
    );
};

export default ScrollNavbarMobile;
  