// import modules, libraries, pages, hooks and components
import BackToTopArrow from "./BackToTopArrow";
import Socials from "./Socials";

const Footer = () => {
    return (
        <footer>
            <BackToTopArrow />
            <Socials size="xl" />
            <p style={{
                textAlign: "center",
                marginTop: "10px", 
                marginLeft: "auto", 
                marginRight: "auto", 
                padding: "0 10px", 
            }}>
                &copy; {new Date().getFullYear()} devfidelis.com - A product of TinnovAce. All rights reserved.
            </p>
        </footer>
    );
}
 
export default Footer;
