// import modules, libraries, pages, hooks and components
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

// import images and icons
import soon from "../asserts/img/soon.png";

const Blog = () => {
    // media queries 
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <main className="blog">
            <img src={soon} alt="404" style={isMobile ? (
                {
                    width: '300px',
                    height: 'auto',
                }
            ) : (
                {
                    width: '450px',
                    height: 'auto'
                }
            )} />
            <Link to="/" style={{display: "block", color: "dodgerblue", marginTop: "10px",}} >Go Home</Link>
        </main>
    );
}
 
export default Blog;
