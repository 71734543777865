// import modules, libraries, pages, hooks and components
import { useState } from 'react';
import axios from 'axios';
import Socials from './Socials';

// import images and icons
import contactImage from '../asserts/img/contact.gif';

const Contact = () => {
    // state management 
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setError('');
            const response = await axios.post('https://server.devfidelis.com/send-email', formData);
            setMessage(response.data); 
        } catch (error) {
            setMessage('');
            setError('Error sending email. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="contact" id="contact">
            <h3>Contact</h3>
            <h2>Get In Touch</h2>
            <div className="contact-box">
                <div className="contact-box-lefter">
                    <img src={contactImage} alt="" />
                    <p>
                        I'm always thrilled to receive messages from you! 
                        Feel free to reach out through any of the channels listed below, 
                        or drop me an email using the contact form. 
                        I can't wait to connect with you!
                    </p>
                    <h4>Phone Call or WhatsApp: +260966091961</h4>
                    <Socials size="2xl" />
                </div>
                <div className="contact-box-righter">
                    <form onSubmit={handleSubmit}>
                        <label>Name:</label>
                        <input type="text" name="name" onChange={handleChange} required />

                        <label>Email:</label>
                        <input type="email" name="email" onChange={handleChange} required />

                        <label>Subject:</label>
                        <input type="text" name="subject" onChange={handleChange} required />

                        <label>Message:</label>
                        <textarea name="message" rows="10" cols="100" onChange={handleChange} required />

                        <button 
                            type="submit" 
                            className="btn" 
                            disabled={loading}>
                            {loading ? 'Sending...' : 'Send Message'}
                        </button>

                        {error && <p className='error'>{error}</p>}
                        {message && <p className='success'>{message}</p>}
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
