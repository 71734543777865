// import modules, libraries, pages, hooks and components
import { Link } from 'react-router-dom';

// import images and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const Portfolio = () => {
    return (
        <section className="portfolio" id="portfolio">
            <h3>Portfolio</h3>
            <h2>Proof of My Work</h2>
            <div className="portfolio-section">
                <Link to="https://citynewscentre.com/" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-one"></div>
                    <h2>City News Centre</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I developed both the frontend, backend and handled the SEO.
                    </p>
                    <p>
                        <span>About:</span> City News Centre is a football news and 
                        updates blog that aims to report accurately about everything football. 
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="https://zedscoop.com/" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-three"></div>
                    <h2>Zedscoop</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I developed both the frontend, backend and improved their SEO.
                    </p>
                    <p>
                        <span>About:</span> Zedscoop is a music streaming/download and all round entertainment blog that delivers 
                        Zambian music-to-music lovers both home and in diaspora. 
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="https://bolayapazed.com/" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-two"></div>
                    <h2>Bola Yapa Zed</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I developed the backend and improved their SEO.
                    </p>
                    <p>
                        <span>About:</span> Bola Yapa Zed is a blog that reports about Zambian football clubs, 
                        sports fixtures, player biography and local football news. 
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="https://github.com/DevFidelis/Moviefy-Project" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-four"></div>
                    <h2>Moviefy</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I developed the frontend and majority of the backend.
                    </p>
                    <p>
                        <span>About:</span> Moviefy is a movie recommendation and analysis system that suggests a movie to the user 
                        based on their moods or other users ratings using historical data. 
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="https://www.justzedmuzik.co/" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-five"></div>
                    <h2>Just Zed Muzik</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I fixed their Spamdexing hack and did their SEO.
                    </p>
                    <p>
                        <span>About:</span> Just Zed Muzik is your go-to hub for streaming, downloading, and enjoying the best 
                        of Zambian music, catering to music lovers at home and abroad. 
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="https://www.npmjs.com/package/cc-time-ago" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-six"></div>
                    <h2>Time Ago Package</h2>
                    <p className="work-done">
                        <span>Work Done:</span> Sole contributor and the owner of the package so I develop everything and manage it's evolution.
                    </p>
                    <p>
                        <span>About:</span> A module that calculates and displays time elapsed in a user-friendly format like 
                        "just now," "1 second ago," "2 minutes ago," "2 hours ago," "1 day ago," etc.
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="#" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-nine"></div>
                    <h2>FunTauni - Offline</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I was the founder & CEO, I developed everything from scratch, front-to-backend.
                    </p>
                    <p>
                        <span>About:</span> FunTauni, with over 30,000 users, 
                        was a vibrant social platform for connecting, making friends, 
                        playing online games, and sharing moments & ideas. Regrettably, 
                        it no longer exists due to various unforeseen reasons (source code still available).
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="#" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-eight"></div>
                    <h2>Student Accommodation - Offline</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I developed both the frontend and the backend using the MERN stack.
                    </p>
                    <p>
                        <span>About:</span> A student accommodation system simplifying the application and management process for university students and staff. However, the system is offline, I can share the source code if needed.
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
                <Link to="https://afrofire.com/author/fraze/" target="_blank" className="portfolio-section-box">
                    <div className="portfolio-section-box-img img-seven"></div>
                    <h2>Afrofire Limited</h2>
                    <p className="work-done">
                        <span>Work Done:</span> I am passionate about music, on a part-time basis, I work at afrofire as a blogger.
                    </p>
                    <p>
                        <span>About:</span> AfroFire.com is one of the most visited music streaming/Download websites in 
                        Zambia serving over 130,000 visitors monthly with an average of around 1,200,000 page views.
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" style={{color: "dodgerblue",}} />
                    </p>
                </Link>
            </div>
        </section>
    );
}
 
export default Portfolio;
